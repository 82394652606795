import {Component, Input} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import * as jwtDecode from 'jwt-decode';
import {DataService} from '../../services/data.service';


@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
  @Input()
  data: any;

  constructor() {}

  onClickCopy() {
    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = JSON.stringify(this.data, null, 2);
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');  // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.error('Copy to clipboard failed.', ex);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
}
