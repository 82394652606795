import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {JsonWidgetPopupComponent} from "../json-widget-popup/json-widget-popup.component";

@Component({
  selector: 'app-code-pop-up',
  templateUrl: './code-pop-up.component.html',
  styleUrls: ['./code-pop-up.component.scss'],
})
export class CodePopUpComponent {
  @Input()
  data: any = null;

  @Input()
  title: string;

  constructor(private matDialog: MatDialog) {
  }

  onJsonClick($event) {
    this.matDialog.open(JsonWidgetPopupComponent, {data:{title:this.title, data: this.data}});
    // console.log(this.data);
    $event.stopPropagation();
    return false;
  }
}


