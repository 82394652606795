import {Component, OnInit} from '@angular/core';
import {DataService, LcaHttpParams} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-code-groups',
    templateUrl: './code-groups.component.html',
    styleUrls: ['./code-groups.component.scss'],
})
export class CodeGroupsComponent implements OnInit {
    data: any = null;
    activeCodeGroup: any = null;
    activeCodeGroupString: string = null;
    title: string;
    endpoint: string;
    loading = true;
    mode = 'list';
    groupOrderListString: string = null;

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet,
                private http: HttpClient) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
        console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
    }

    ngOnInit() {
        // console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
        this.getEndpointData();
    }

    getEndpointData() {
        this.data = null;
        this.dataService.getEndpointData(this.endpoint).subscribe((data: {}) => {
            const codes: string[] = [];
            this.data = data || {};
            this.data.forEach(codeGroup => {
                codes.push(codeGroup.code);
            });
            this.groupOrderListString = JSON.stringify(codes, null, 2);
            this.loading = false;
        }, (error) => {
            this.data = (error && error.error) || error || {};
            this.groupOrderListString = null;
            this.loading = false;
        });
    }

    showList(): void {
        this.mode = 'list';
    }

    showCodeGroup(code: string) {
        this.mode = 'displayActive';
        this.loading = true;
        const endpoint = '/code-groups/code-group';
        const params: LcaHttpParams = {};
        params.code = code;
        this.dataService.getEndpointData(endpoint, params).subscribe((data: {}) => {
            this.activeCodeGroup = data || {};
            this.loading = false;
        }, (error) => {
            this.activeCodeGroup = (error && error.error) || error || {};
            this.loading = false;
        });
    }

    editActiveCodeGroup() {
        this.mode = 'editActive';
        this.activeCodeGroupString = this.getActiveCodeGroupString(this.activeCodeGroup);
    }

    getActiveCodeGroupString(activeCodeGroup): any {
        const copy = this.deepCopy(activeCodeGroup);
        delete (copy.type);
        return JSON.stringify(copy, null, 2);
    }


    saveGroupOrderList() {
        try {
            const groupOrderList = JSON.parse(this.groupOrderListString);
            this.dataService.putEndpointData(this.endpoint, groupOrderList)
                .subscribe((data: {}) => {
                    const codes: string[] = [];
                    this.data = data || {};
                    this.data.forEach(codeGroup => {
                        codes.push(codeGroup.code);
                    });
                    this.groupOrderListString = JSON.stringify(codes, null, 2);
                    this.mode = 'list';
                    this.loading = false;
                }, (error) => {
                    this.groupOrderListString = JSON.stringify(error, null, 2);
                    this.loading = false;
                });
        } catch (e) {
            window.alert(e);
        }
    }

    deleteGroupOrderList() {
        try {
            this.dataService.deleteEndpointData(this.endpoint)
                .subscribe((data: {}) => {
                    const codes: string[] = [];
                    this.data = data || {};
                    this.data.forEach(codeGroup => {
                        codes.push(codeGroup.code);
                    });
                    this.groupOrderListString = JSON.stringify(codes, null, 2);
                    this.mode = 'list';
                    this.loading = false;
                }, (error) => {
                    this.groupOrderListString = JSON.stringify(error, null, 2);
                    this.loading = false;
                });
        } catch (e) {
            window.alert(e);
        }

    }

    saveActiveCodeGroupString() {
        try {
            const updateActiveCodeGroup = JSON.parse(this.activeCodeGroupString);
            if (!updateActiveCodeGroup.code) {
                throw new Error('I need a code');
            }
            if (!updateActiveCodeGroup.target) {
                throw new Error('I need a target');
            }
            if (!Array.isArray(updateActiveCodeGroup.target)) {
                throw new Error('target must be an array');
            }

            const params: LcaHttpParams = {};
            params.code = updateActiveCodeGroup.code;
            const endpoint = '/code-groups/code-group';
            this.dataService.putEndpointData(endpoint, updateActiveCodeGroup.target, params)
                .subscribe((data: {}) => {
                    this.activeCodeGroup = data;
                    this.mode = 'displayActive';
                    this.loading = false;
                }, (error) => {
                    this.activeCodeGroupString = JSON.stringify(error, null, 2);
                    this.loading = false;
                });
        } catch (e) {
            window.alert(e);
        }
    }

    deleteActiveCodeGroup() {
        this.deleteCodeGroup(this.activeCodeGroup.code);
    }

    deleteNonExistantCodeGroup() {
        this.deleteCodeGroup(this.getRandomId());
    }

    deleteCodeGroup(code: string) {
        const endpoint = '/code-groups/code-group';
        const params: LcaHttpParams = {};
        params.code = code;

        this.dataService.deleteEndpointData(endpoint, params)
            .subscribe((data: {}) => {
                const codes: string[] = [];
                this.data = data || {};
                this.data.forEach(codeGroup => {
                    codes.push(codeGroup.code);
                });
                this.groupOrderListString = JSON.stringify(codes, null, 2);
                this.loading = false;
                this.mode = 'list';
            }, (error) => {
                this.data = (error && error.error) || error || {};
                this.groupOrderListString = null;
                this.loading = false;
                this.mode = 'list';
            });
    }


    deepCopy(data: any): any {
        if (!data) {
            return data;
        }
        return JSON.parse(JSON.stringify(data));
    }

    getRandomId(): string {
        return Math.random().toString(26).slice(2);
    }

}
