import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

export enum SelectionMode {
  BY_USE_CONTEXT,
  MANUAL
}
@Component({
  selector: 'app-clinical-reasoning-by-plan-definition',
  templateUrl: './clinical-reasoning-by-plan-definition.component.html',
  styleUrls: ['./clinical-reasoning-by-plan-definition.component.scss'],
})
export class ClinicalReasoningByPlanDefinitionComponent implements OnInit {
  data: any = null;
  title: string;
  loading = true;
  planDefinitions: any[] = [];
  planDefinition: any;
  planDefinitionIdManual: string;
  xxx: string;
  SelectionMode = SelectionMode;
  selectionMode : SelectionMode = SelectionMode.BY_USE_CONTEXT;
  code = 'CDSREV';
  system = 'http://terminology.hl7.org/CodeSystem/v3-ActCode';

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
  }

  ngOnInit() {
    // console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
    this.getPlanDefinitions();
  }

  hasPlanDefinitions() {
    return this.planDefinitions && this.planDefinitions.length > 0;
  }

  getPlanDefinitions() {
    this.planDefinitions = [];
    const endpoint = `/clinical-reasoning/PlanDefinition?system=${encodeURIComponent(this.system)}&code=${this.code}`;
    this.dataService.getEndpointData(endpoint).subscribe((data: any) => {
      data.entry.forEach(entry => {
        this.planDefinitions.push({id: entry.resource.id, title: entry.resource.title});
      });

      this.data = null;
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
  }

  updateGuidanceResponse() {
    this.update('GuidanceResponse');
  }

  updateInputs() {
    this.update('Inputs');
  }

  updateDataRequirements() {
    this.update('DataRequirements');
  }

  isButtonDisabled() {
    return !!!this.getPlanDefinitionId();
  }

  getPlanDefinitionId() {
    if (this.selectionMode === SelectionMode.BY_USE_CONTEXT) {
      return this.planDefinition?.id;
    } else {
      return this.planDefinitionIdManual;
    }
  }

  update(type: string) {
    this.data = null;
    const planDefinitionId = this.getPlanDefinitionId();

    if (planDefinitionId) {
      this.loading = true;
      const endpoint = `/clinical-reasoning/${type}/${planDefinitionId}`;
      this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
        this.data = data || {};
        this.loading = false;
      }, (error) => {
        this.data = (error && error.error) || error || {};
        this.loading = false;
      });
    }
  }
}
