import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {BaseSubscriber} from '../../../utils/base-subscriber';
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseSubscriber implements OnInit {
  title = 'Elvis POC UI';
  iss: string;
  launch: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataService: DataService) {
    super();
  }

  openSwagger() {
    window.open(`${this.dataService.getElvisRestAPI()}/swagger-ui/`)
  }
  routeToWithoutPopup($event, page: string) {
    this.router.navigate([page]);
    $event.stopPropagation();
    return false;
  }

  routeTo(page: string) {
    this.router.navigate([page]);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.iss = params['iss'];
      this.launch = params['launch'];
    });
  }
}
