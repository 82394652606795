import { Component, Injectable, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

export enum SelectionMode {
  BY_USE_CONTEXT,
  MANUAL
}
@Component({
  selector: 'app-search-plan-definition-by-coding',
  templateUrl: './search-plan-definition-by-coding.component.html',
  styleUrls: ['./search-plan-definition-by-coding.component.scss']
})
export class SearchPlanDefinitionByCodingComponent {
  @Input()
  planDefinitions: any[];
  planDefinition: any;
  planDefinitionIdManual: string;
  data: any = null;
  loading = false;
  SelectionMode = SelectionMode;
  selectionMode : SelectionMode = SelectionMode.BY_USE_CONTEXT;

  constructor(private dataService: DataService) { }

  hasPlanDefinitions() {
    return this.planDefinitions && this.planDefinitions.length > 0;
  }

  isButtonDisabled() {
    return !!!this.getPlanDefinitionId();
  }

  getPlanDefinitionId() {
    if (this.selectionMode === SelectionMode.BY_USE_CONTEXT) {
      return this.planDefinition?.id;
    } else {
      return this.planDefinitionIdManual;
    }
  }

  updateGuidanceResponse() {
    this.update('GuidanceResponse');
  }

  updateInputs() {
    this.update('Inputs');
  }

  updateDataRequirements() {
    this.update('DataRequirements');
  }

  update(type: string) {
    this.data = null;
    const planDefinitionId = this.getPlanDefinitionId();

    if (planDefinitionId) {
      this.loading = true;
      const endpoint = `/clinical-reasoning/${type}/${planDefinitionId}`;
      this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
        this.data = data || {};
        this.loading = false;
      }, (error) => {
        this.data = (error && error.error) || error || {};
        this.loading = false;
      });
    }
  }

}
