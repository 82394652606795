import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';


export interface LcaHttpParams {
  [param: string]: string | string[];
}

@Injectable({providedIn: 'root'})
export class DataService {

  private elvisRestAPI: string;

  constructor(private http: HttpClient) {
  }

  public setElvisRestAPI(elvisRestAPI: string): void {
    this.elvisRestAPI = elvisRestAPI;
  }

  public getElvisRestAPI(): string {
    return this.elvisRestAPI;
  }

  public getEndpointData(endpoint: string, params?: LcaHttpParams, headers?: HttpHeaders): Observable<any> {
    if (!this.elvisRestAPI) {
      throw new Error('elvisRestAPI has not been set');
    }

    return this.http
      .get<any>(`${this.elvisRestAPI}${endpoint}`, {params, headers})
      .pipe(map(this.extractData));
  }

  public postEndpointData(endpoint: string, data: any, params?: LcaHttpParams): Observable<any> {
    let endpointFinal;
    if(endpoint.startsWith("http://") || endpoint.startsWith("https://")) {
      endpointFinal = endpoint;
    } else {
      if (!this.elvisRestAPI) {
        throw new Error('elvisRestAPI has not been set');
      }
      endpointFinal = `${this.elvisRestAPI}${endpoint}`;
    }
    return this.http
      .post<any>(endpointFinal, data,{params: params})
      .pipe(map(this.extractData));
  }

  public putEndpointData(endpoint: string, data: any, params?: LcaHttpParams): Observable<any> {
    if (!this.elvisRestAPI) {
      throw new Error('elvisRestAPI has not been set');
    }

    return this.http
      .put<any>(`${this.elvisRestAPI}${endpoint}`, data, {params: params})
      .pipe(map(this.extractData));
  }

  public deleteEndpointData(endpoint: string, params?: LcaHttpParams): Observable<any> {
    if (!this.elvisRestAPI) {
      throw new Error('elvisRestAPI has not been set');
    }
    return this.http
      .delete<any>(`${this.elvisRestAPI}${endpoint}`, {params: params})
      .pipe(map(this.extractData));
  }

  public getEndpointPdf(endpoint: string): Observable<any> {
    if (!this.elvisRestAPI) {
      throw new Error('elvisRestAPI has not been set');
    }
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http
      .get(`${this.elvisRestAPI}${endpoint}`, {headers: headers, responseType: 'blob'})
      .pipe(map(this.extractBlob));
  }

  public extractData(res: Response) {
    const body = res;
    return body || {};
  }

  public extractBlob(res: Blob) {
    const body = res;
    return body || {};
  }
}
