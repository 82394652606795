import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-ers-direct',
  templateUrl: './ers-direct.component.html',
  styleUrls: ['./ers-direct.component.scss'],
})
export class ErsDirectComponent implements OnInit {
  data: any = undefined;
  endpoint: string;
  error: any = undefined;
  title: string;
  lpid: string;
  loading = false;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData.title;
    this.endpoint = routerOutlet.activatedRouteData.endpoint;
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit() {
    // console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
    // this.getEndpointData();
  }

  update() {
    this.getEndpointData();
  }

  getEndpointData() {
    this.loading = true;
    this.data = null;
    this.error = null;
    let endpoint = `${this.endpoint}`;
    if (this.lpid && this.lpid.trim().length > 0) {
      endpoint = `${this.endpoint}?lpid=${this.lpid.trim()}`;
    }
    try {
      this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
        this.data = data || {};
        this.loading = false;
      }, (error) => {
        this.error = JSON.stringify(error, null, 2);
        this.loading = false;
      });
    } catch (error) {
      this.error = JSON.stringify(error, null, 2);
      this.loading = false;
    }
  }
}
