import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Injectable({providedIn: 'root'})
export class AuthService {
  private lvsAuthInfo: any;

  constructor(private http: HttpClient) {
  }

  setLvsAuthInfo(lvsAuthInfo) {
    this.lvsAuthInfo = lvsAuthInfo;
  }

  getJwt(): string {
    return this.lvsAuthInfo ? this.lvsAuthInfo.jwt : null;
  }

  getRoles() {
    return this.lvsAuthInfo ? this.lvsAuthInfo.roles : null;
  }

  getLvsAuthInfo() : any {
    return this.lvsAuthInfo;
  }
}
