import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {Panel} from '../../../../models/Panel';
import {Result} from '../../../../models/Result';
import {Bundle, Coding, DomainResource, Observation, Resource} from "fhir/r4";
import {
  codingDisplayName,
  Duplicate,
  getCodeGroups,
  getDuplicates, getLoincCodes,
  isAbnormal, isNew,
  getSourceAndIdKey, observationValue, panelDisplayName,
  updatePanelsAndResults
} from '../../../../../utils/resource-utils';
import {getClinicalViewPortDefaultFilterData, getObservationDefaultFilterData} from "../../../../../utils/data-filter-utils";

/**
 * if includePanels is true, then we return all DiagnosticReports that have Observations referenced in the page
 * in the UI, we will show that in the columns. If an Observation doesn't HAVE a DiagnosticReport, we will synthesize
 * that. and create a dummy Panel object, with the effectiveDate, and a UUID.
 */
export enum DisplayMode {
  API_RESPONSE,
  CLINICAL_VIEW_JSON,
  CLINICAL_VIEW_UI
}

export class PanelCodeGroupData {
  panel: Panel;
  codeGroupDatas = new Map<string, CodeGroupData>();
}

export class RowData {
  loinc: Coding
  results: Result[] = [];
}

export class CodeGroupData {
  codeGroup: string;
  resultsByLoincCode = new Map<string, RowData>();
}

export interface ICodeGroup {
  code: string,
  type: string,
  order?: number,
  count?: number
}

export interface IStats {
  panelCount?: number,
  codeGroupsCount?: number,
  loincCodesCount?: number
}

const NOT_GROUPED_BY_LVS_REST = {
  code: "Not Grouped By lvs-rest",
  type: "emr-defined"
};

@Component({
  selector: 'app-lvs-ui-panel-view',
  templateUrl: './lvs-ui-chemistry-panel-port.component.html',
  styleUrls: ['./lvs-ui-chemistry-panel-port.component.scss'],
})
export class LvsUiChemistryPanelPortComponent implements OnInit {
  data: any = null;
  panels: Panel[] = [];
  panelCodeGroupDatas: PanelCodeGroupData[] = null;
  codeGroups: ICodeGroup[] = [];
  title: string;
  endpoint: string;
  loading = false;
  DisplayMode = DisplayMode;
  displayMode: DisplayMode = DisplayMode.API_RESPONSE;
  showNoCodeGroup: boolean = false;
  stats: IStats = {};

  filterDataString: string = JSON.stringify(getClinicalViewPortDefaultFilterData(), null, 2);

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
  }

  ngOnInit(): void {
    this.getCodeGroupsAndEndpointData();
  }

  toggleShowNoCodeGroup() {
    if (!this.showNoCodeGroup) {
      this.codeGroups = this.codeGroups.filter(codeGroup => {
        return codeGroup.code !== NOT_GROUPED_BY_LVS_REST.code;
      });
    } else {
      this.codeGroups.push(NOT_GROUPED_BY_LVS_REST);
    }
    this.updateObjects();
  }

  updateObjects() {
    updatePanelsAndResults(this.panels, this.data);
    updateCodeGroupCounts(this.panels, this.codeGroups);
    this.panelCodeGroupDatas = getPanelCodeGroupDatas(this.panels);
    this.stats.panelCount = this.panelCodeGroupDatas.length;
    this.stats.loincCodesCount = 0;
    this.stats.codeGroupsCount = this.codeGroups.filter(codeGroup => codeGroup.count > 0).length;
    // this.stats.loincCodesCount
    this.panelCodeGroupDatas.forEach(panelGroupData => {
      panelGroupData.codeGroupDatas.forEach(codeGroupData => {
        this.stats.loincCodesCount += codeGroupData.resultsByLoincCode.size;
      });
    });
  }


  getEndpointData() {
    this.loading = true;
    this.data = null;
    const filterData = JSON.parse(this.filterDataString);
    this.dataService.postEndpointData(this.endpoint, filterData).subscribe((data: {}) => {
      this.data = data || {};
      this.updateObjects();
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.updateObjects();
      this.loading = false;
    });
  }

  getNodeJsData() {
    const endpoint = "http://lvs-docker-dev/api/clinical-view/chemistry-panel";
    this.loading = true;
    this.data = null;
    const filterData = JSON.parse(this.filterDataString);
    this.dataService.postEndpointData(endpoint, filterData).subscribe((data: {}) => {
      this.data = data || {};
      this.updateObjects();
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.updateObjects();
      this.loading = false;
    });
  }

  getCodeGroupsAndEndpointData() {
    this.codeGroups = [];
    this.dataService.getEndpointData('/code-groups/code-group-order?verbose=false').subscribe((data: []) => {
      this.codeGroups = data || [];
      if (this.showNoCodeGroup) {
        this.codeGroups.push({
          code: NOT_GROUPED_BY_LVS_REST.code,
          type: "emr-defined"
        });
      }
      let codeGroupOrder = 0;
      for (let codeGroup of this.codeGroups) {
        codeGroup.order = codeGroupOrder++;
      }
      this.getEndpointData();
    }, (error) => {
      this.codeGroups = (error && error.error) || error || {};
    });
  }

  getDuplicates(resource: DomainResource): Duplicate[] {
    return getDuplicates(resource);
  }

  getDuplicatesAsString(resource: DomainResource): string {
    const tmp: string[] = [];
    getDuplicates(resource)?.forEach(duplicate => {
      tmp.push(`${duplicate.reference} (${duplicate.sourceSystem})`);
    });
    return tmp.join(', ');
  }

  isNew(resource: Resource): boolean {
    return isNew(resource);
  }

  isAbnormal(resource: Resource) : boolean {
    return isAbnormal(resource);
  }


  getAllCodeGroups(): string[] {
    const returnValue = [];
    this.codeGroups.forEach(codeGroup => {
      if (codeGroup.count > 0) {
        returnValue.push(codeGroup.code);
      }
    });
    return returnValue;
  }

  codingDisplayName(loinc: Coding) {
    return codingDisplayName(loinc);
  }

  observationValue(observation: Observation) {
    return observationValue(observation);
  }

  getAllLoincs(codeGroup: string): Coding[] {
    const returnValue: Coding[] = [];
    const alreadyProcessed = new Set<String>();
    for (const panelCodeGroupData of this.panelCodeGroupDatas) {
      const codeGroupData = panelCodeGroupData.codeGroupDatas.get(codeGroup);
      codeGroupData?.resultsByLoincCode.forEach(rowData => {
        if (!alreadyProcessed.has(rowData.loinc.code)) {
          alreadyProcessed.add(rowData.loinc.code);
          returnValue.push(rowData.loinc);
        }
      });
    }
    return returnValue;
  }

  getResults(panelCodeGroupData: PanelCodeGroupData, codeGroup: string, loincCode: string): Result[] {
    const codeGroupData = panelCodeGroupData.codeGroupDatas.get(codeGroup);
    if (codeGroupData) {
      const rowData = codeGroupData.resultsByLoincCode.get(loincCode);
      if (rowData) {
        return rowData.results;
      }
    }
    return null;
  }

  getLoincCodeCount() {
    let returnValue: number = 0;
    this.panelCodeGroupDatas.forEach(panelGroupData => {
      panelGroupData.codeGroupDatas.forEach(codeGroupData => {
        returnValue += codeGroupData.resultsByLoincCode.size;
      });
    });
    return returnValue;
  }


  onBookmarkFail(error$: any) {
    console.error("onBookmarkFail", error$);
  }

  onBookmarkSuccess(resource$: Resource) {
    this.getEndpointData();
  }
}

function updateCodeGroupCounts(panels: Panel[], codeGroups: any) {
  codeGroups.forEach(codeGroup => {
    codeGroup.count = 0;
    panels?.forEach(panel => {
      panel?.results.forEach(result => {
        let codeGroupsCur = getCodeGroups(result.observation);
        if (codeGroupsCur.length===0) {
          codeGroupsCur = [NOT_GROUPED_BY_LVS_REST.code];
        }
        for(const codeGroupCur of codeGroupsCur) {
          if (codeGroup?.code === codeGroupCur) {
            codeGroup.count++;
          }
        }
      });
    });
  });
}

function getPanelCodeGroupDatas(panels: Panel[]): PanelCodeGroupData[] {
  const returnValue: PanelCodeGroupData[] = [];
  panels.forEach(panel => {
    const sourceAndIdKey = getSourceAndIdKey(panel.diagnosticReport);
    const panelGroupData = new PanelCodeGroupData();
    panelGroupData.panel = panel;
    let hasAtLeastOneLoinc = false;
    panel.results.forEach(result => {
      const loincCodes = getLoincCodes(result);
      if (loincCodes.length > 0) {
        hasAtLeastOneLoinc = true;
        let codeGroups: string[] = getCodeGroups(result.observation);
        if (codeGroups.length === 0) {
          codeGroups.push(NOT_GROUPED_BY_LVS_REST.code);
        }
        for (const codeGroup of codeGroups) {
          let codeGroupData = panelGroupData.codeGroupDatas.get(codeGroup);
          if (!codeGroupData) {
            codeGroupData = new CodeGroupData();
            codeGroupData.codeGroup = codeGroup;
            panelGroupData.codeGroupDatas.set(codeGroup, codeGroupData);
          }
          for (const loincCode of loincCodes) {
            let rowData = codeGroupData.resultsByLoincCode.get(loincCode.code);
            if (!rowData) {
              rowData = new RowData();
              rowData.loinc = loincCode;
              codeGroupData.resultsByLoincCode.set(loincCode.code, rowData);
            }
            rowData.results.push(result);
          }
        }
      }
    });
    if (hasAtLeastOneLoinc) {
      returnValue.push(panelGroupData);
    }
  });
  return returnValue;
}


