import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {Panel} from '../../../../models/Panel';
import {DiagnosticReport, DomainResource, Resource} from "fhir/r4";
import {
  Duplicate,
  getDuplicates, getSource, isAbnormal,
  isNew, isSearchMatch,
  updatePanelsAndResults
} from '../../../../../utils/resource-utils';
import {getDiagnosticReportDefaultFilterData} from "../../../../../utils/data-filter-utils";
import {MatDialog} from "@angular/material/dialog";
import {PractitionerTagsService} from "../../../../services/practitionerTags.service";

export enum DisplayMode {
  API_RESPONSE,
  PANEL_VIEW_JSON,
  PANEL_VIEW_UI
}

@Component({
  selector: 'app-lvs-ui-panel-view',
  templateUrl: './lvs-ui-panel-view-port.component.html',
  styleUrls: ['./lvs-ui-panel-view-port.component.scss'],
})
export class LvsUiPanelViewPortComponent implements OnInit {

  data: any = null;
  panels: Panel[] = [];
  title: string;
  endpoint: string;
  loading = false;
  DisplayMode = DisplayMode;
  displayMode: DisplayMode = DisplayMode.API_RESPONSE;
  filterDataString: string = JSON.stringify(getDiagnosticReportDefaultFilterData(), null, 2);

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet,
              private matDialog: MatDialog,
              private practitionerTagsService: PractitionerTagsService) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit(): void {
    this.postEndpointData();
  }

  postEndpointData() {
    this.loading = true;
    this.data = null;
    const filterData = JSON.parse(this.filterDataString);
    this.dataService.postEndpointData(this.endpoint, filterData).subscribe((data: {}) => {
      this.data = data || {};
      updatePanelsAndResults(this.panels, this.data);
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      updatePanelsAndResults(this.panels, null);
      this.loading = false;
    });
  }

  getDuplicates(resource: DomainResource): Duplicate[] {
    return getDuplicates(resource);
  }

  isSearchMatch(resource: Resource): boolean {
    return isSearchMatch(resource);
  }

  isNew(resource: Resource): boolean {
    return isNew(resource);
  }

  isAbnormal(resource: Resource) : boolean {
    return isAbnormal(resource);
  }

  getDuplicatesAsString(resource: DomainResource): string {
    const tmp: string[] = [];
    getDuplicates(resource)?.forEach(duplicate => {
      tmp.push(`${duplicate.reference} (${duplicate.sourceSystem})`);
    });
    return tmp.join(', ');
  }

  setAllExpand(value: boolean) {
    this.panels.forEach(panel => {
      panel.expanded = value;
    });
  }

  onBookmarkFail(error$: any) {
      console.error("onBookmarkFail",error$);
  }

  onBookmarkSuccess(resource$: Resource) {
     this.postEndpointData();
  }
}


