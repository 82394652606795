import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {DataService, LcaHttpParams} from '../../services/data.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-lvs-auth',
  templateUrl: './lvs-auth.component.html',
  styleUrls: ['./lvs-auth.component.scss'],
})
export class LvsAuthComponent implements OnInit {
  lvsAuthInfo: any = null;
  loading = true;
  nonce: string = null;
  base64Config: string = null;
  elvisRestAPI: string = null;
  error: any = null;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private dataService: DataService
  ) {
    activatedRoute.params.subscribe(params => {
      console.log('store', params); // should not be empty if there really are params.
    });
  }

  ngOnInit() {
    try {
      this.authService.setLvsAuthInfo(null);
      this.nonce = this.activatedRoute.snapshot.queryParamMap.get('nonce');
      this.base64Config = this.activatedRoute.snapshot.queryParamMap.get('config');
      if (this.nonce) {
        this.loadFromNonce();
      } else if (this.base64Config) {
        this.loadFromBase64Config();
      } else {
        this.error = 'Expected either nonce or config parameter to be supplied';
      }
    } catch (error) {
      console.log(error);
      this.error = error;
      this.loading = false;
    }
  }

  loadFromNonce() {
    const params: LcaHttpParams = {};
    params.nonce = this.nonce;
    try {
      this.elvisRestAPI = this.getElvisRestAPIFromJwt(this.nonce);
      this.dataService.setElvisRestAPI(this.elvisRestAPI);
      this.dataService.getEndpointData('/lvs-auth', params).subscribe((lvsAuthInfo) => {
        this.applyLvsAuthInfo(lvsAuthInfo);
      }, (error) => {
        this.error = error;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      this.error = error;
      this.loading = false;
    }
  }

  loadFromBase64Config() {
    try {
      const lvsAuthInfo = JSON.parse(window.atob(this.base64Config));
      this.elvisRestAPI = this.getElvisRestAPIFromJwt(lvsAuthInfo.jwt);
      this.dataService.setElvisRestAPI(this.elvisRestAPI);
      this.applyLvsAuthInfo(lvsAuthInfo);
    } catch (error) {
      console.log(error);
      this.error = error;
      this.loading = false;
    }
  }

  applyLvsAuthInfo(lvsAuthInfo: any) {
    this.lvsAuthInfo = lvsAuthInfo || {};
    this.authService.setLvsAuthInfo(this.lvsAuthInfo);
    console.log("lvsAuthInfo", lvsAuthInfo);
    if (this.isLegacyMode(lvsAuthInfo)) {
      this.router.navigate(['/lvs-ui-no-stream-panel-view-progress'], {queryParams: {mode: 'initial'}});
    } else {
      this.router.navigate(['/']);
    }
  }

  // returns true if launch mode is legacy. false otherwise
  private isLegacyMode(lvsAuthInfo: any): boolean {
    try {
      return lvsAuthInfo.parameters.mode[0] === 'legacy';
    } catch (error) {
      return false;
    }
  }

  private getElvisRestAPIFromJwt(nonce: string): string {
    const decodedJwt: any = jwt_decode(nonce);
    if (!(typeof decodedJwt?.iss === "string")) {
      console.error('decodedJwt.iss is not a string', decodedJwt);
    }
    return decodedJwt.iss;
  }
}
