import {Observable} from 'rxjs';
import {DataService, LcaHttpParams} from './data.service';
import {Injectable} from '@angular/core';
import {PractitionerSettings} from '../models/practitionerSettings';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Injectable({providedIn: 'root'})
export class JsonWidgetStateService {
  public mode = 'raw';
}
