import { Component, OnInit } from '@angular/core';
import {DataService, LcaHttpParams} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-ctra-practitioner-role',
  templateUrl: './ctra-practitioner-role.component.html',
  styleUrls: ['./ctra-practitioner-role.component.scss']
})
export class CtraPractitionerRoleComponent implements OnInit {

  data: any = null;
  title: string;
  endpoint: string;
  loading = true;
  mode = 'list';

  email = 'Abruzzn@LabCorp.com';

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet,
              private http: HttpClient) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit(): void {
    // console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    this.loading = false;
  }

  getResource(): void {
    this.data = null;
    this.loading = true;
    let endpoint = `${this.endpoint}`;
    if (this.email.trim().length > 0) {
      endpoint = `${this.endpoint}?email=${this.email.trim()}`;
    }
    const params: LcaHttpParams = {
      email: this.email

    };
    this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
      console.log('success', data);
      this.data = data;
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
    this.mode = 'list';
  }

}
