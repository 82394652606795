import {Component, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-show-endpoint',
  templateUrl: './show-endpoint.component.html',
  styleUrls: ['./show-endpoint.component.scss'],
})
export class ShowEndpointComponent implements OnInit {
  data: any = null;
  title: string;
  endpoint: string;
  subTitle: string;
  summary: string;
  loading = true;
  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    this.subTitle = routerOutlet.activatedRouteData['subTitle'];
    this.summary = routerOutlet.activatedRouteData['summary'];
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit() {
    console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    this.dataService.getEndpointData(this.endpoint).subscribe((data: {}) => {
      this.data = data || {};
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
  }
}
