import { Component, OnInit } from '@angular/core';
import {DataService, LcaHttpParams} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-ctra-resource',
  templateUrl: './ctra-resource-path.component.html',
  styleUrls: ['./ctra-resource-path.component.scss']
})
export class CtraResourcePathComponent implements OnInit {

  data: any = null;
  title: string;
  endpoint: string;
  loading = true;
  mode = 'list';
  resourcesAvailable: any[] = [];
  resourceAvailable: any;
  resource = 'Practitioner';
  pathparam = '1304';

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet,
              private http: HttpClient) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit(): void {
    // console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
    this.getEndpointData();
    this.getResourcesAvailable();
  }

  getEndpointData() {
    this.data = null;
    this.loading = false;
  }

  getResource(): void {
    this.data = null;
    this.loading = true;
    let endpoint = `${this.endpoint}`;
    if (this.resource.trim().length > 0) {
      endpoint = `${this.endpoint}${this.resource.trim()}/${this.pathparam.trim()}`;

    }
    const params: LcaHttpParams = {
      resource: this.resource

    };
    this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
      console.log('success', data);
      this.data = data;
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
    this.mode = 'list';
  }

  getResourcesAvailable() {
    this.resourcesAvailable = ['Practitioner','Organization','Group','ResearchSubject','QuestionnaireResponse']
  }

  update(type: string) {
    console.log("Update", `${type}`)
   this.resource = `${type}`;
    //this.getResource()
  }

}
