import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-show-paged-data',
    templateUrl: './show-paged-data.component.html',
    styleUrls: ['./show-paged-data.component.scss'],
})
export class ShowPagedDataComponent implements OnInit {
    data: any = null;
    title: string;
    endpoint: string;
    loading = true;
    pageSizes = [1, 10, 20, 50, 100, 9999999];
    pageSize = 10;
    pageNumber = 1;
    count: number = null;

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private routerOutlet: RouterOutlet) {
        this.title = routerOutlet.activatedRouteData['title'];
        this.endpoint = routerOutlet.activatedRouteData['endpoint'];
        console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
    }

    ngOnInit() {
        // console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
        this.getEndpointData();
    }

    update() {
        this.getEndpointData();
    }

    getEndpointData() {
        this.data = null;
        const endpoint = `${this.endpoint}?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`;
        this.dataService.getEndpointData(endpoint).subscribe((data: {}) => {
            this.count = 0;
            this.data = data || {};
            if (this.data && Array.isArray(this.data.entry)) {
                this.count = this.data.entry.length;
            }
            this.loading = false;
        }, (error) => {
            this.count = null;
            this.data = (error && error.error) || error || {};
            this.loading = false;
        });
    }
}
