import {Component, OnInit} from '@angular/core';
import {DataService, LcaHttpParams} from '../../services/data.service';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-read-resource',
  templateUrl: './read-resource.component.html',
  styleUrls: ['./read-resource.component.scss'],
})
export class ReadResourceComponent implements OnInit {
  data: any = null;
  title: string;
  endpoint: string;
  loading = true;
  mode = 'list';

  processor = 'Default';
  sourceSystem = 'Demo EMR';
  resourceType = 'Condition';
  id = '24a03699-64ca-4c17-bf41-6c659429bcf4';

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private routerOutlet: RouterOutlet,
              private http: HttpClient) {
    this.title = routerOutlet.activatedRouteData['title'];
    this.endpoint = routerOutlet.activatedRouteData['endpoint'];
    console.log('routerOutlet1', routerOutlet && routerOutlet.activatedRouteData && routerOutlet.activatedRouteData);
  }

  ngOnInit() {
    // console.log('routerOutlet2', this.routerOutlet && this.routerOutlet.activatedRouteData && this.routerOutlet.activatedRouteData);
    this.getEndpointData();
  }

  getEndpointData() {
    this.data = null;
    this.loading = false;
  }

  getResource(): void {
    this.data = null;
    this.loading = true;
    const params: LcaHttpParams = {
      processor: this.processor,
      sourceSystem: this.sourceSystem,
      resourceType: this.resourceType,
      id: this.id
    };

    this.dataService.getEndpointData(this.endpoint, params).subscribe((data: {}) => {
      console.log('success', data);
      this.data = data;
      this.loading = false;
    }, (error) => {
      this.data = (error && error.error) || error || {};
      this.loading = false;
    });
    this.mode = 'list';
  }
}
